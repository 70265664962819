import bezierEasing from 'bezier-easing';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
// components
import Layout from 'components/Layout';
import Font from 'components/atoms/Font';
// styles
import { media } from 'styles/cryptodartTheme';

const DATA = [
  {
    id: 1,
    text: '데이터 기반의 평가 모델 크립토다트가 만듭니다.',
    animation: {
      startPosition: -0.05,
      endPosition: 0.25,
    },
  },
  {
    id: 2,
    text: '디지털자산의 기술적 안정성 크립토다트가 검증합니다.',
    animation: {
      startPosition: 0.25,
      endPosition: 0.6,
    },
  },
  {
    id: 3,
    text: '디지털자산의 법률파트너 크립토다트가 지원합니다.',
    animation: {
      startPosition: 0.6,
      endPosition: 1,
    },
  },
];

const Section2 = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textBoxRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<Array<HTMLDivElement | null>>([]);
  const ease = bezierEasing(0.0, 0.64, 0.01, 0.99); // 빠르게 올라가다가 중간쯤에선 거의 유지
  const easeIn = bezierEasing(0.38, 0.11, 0.78, 0.13); // 처음부터 끝까지 빠르게
  const yEase = bezierEasing(0, 0.7, 1, 0.3); // 천천히 늘어나다가 확 높아짐

  const getValueFromRate = (start, end, rate) => {
    return start + (end - start) * rate;
  };

  useEffect(() => {
    if (textRef.current[0]) {
      textRef.current[0].style.opacity = '1';
    } else return;

    const handleScroll = () => {
      if (containerRef?.current && textBoxRef?.current) {
        const containerOffsetTop = containerRef.current.offsetTop; // 최상단으로 부터 Layout의 상단까지의 길이
        const containerOffsetHeight = containerRef.current.offsetHeight; // Layout의 높이
        const scrollY = window.scrollY; // 현재 스크롤된 거리
        const windowHeight = window.innerHeight; // 현재 창의 높이
        const { height } = textBoxRef.current.getBoundingClientRect(); // text박스 높이
        const stickyStartPosition = window.innerHeight / 2 - height / 2; // videoBox가 sticky로 고정되는 시작점
        const offset = 129 + height + stickyStartPosition;

        const ratio =
          (scrollY + windowHeight - (containerOffsetTop + offset)) /
          (containerOffsetHeight - offset);

        if (ratio >= 0 && ratio <= 1) {
          if (textRef && textRef.current) {
            DATA.forEach((item, index) => {
              const {
                animation: { startPosition, endPosition },
              } = item;
              const ref = textRef.current[index];
              const sectionRatio = (ratio - startPosition) / (endPosition - startPosition);

              if (startPosition <= ratio && ratio <= endPosition) {
                // 현재 진행중인 애니메이션의 진행률
                const currentTransformValue = getValueFromRate(100, -100, yEase(sectionRatio));

                if (index === DATA.length - 1 || index === 0) {
                  if (
                    (!(currentTransformValue < 0) && index === DATA.length - 1) ||
                    (index === 0 && currentTransformValue < 0)
                  ) {
                    // 마지막 텍스트는 화면에서 사라지기 전에 유지
                    ref!.style.transform = `matrix(1, 0, 0, 1, 0, ${currentTransformValue})`;
                    ref!.style.opacity =
                      sectionRatio < 0.6
                        ? getValueFromRate(0.2, 1, ease(sectionRatio))
                        : getValueFromRate(1, 1, easeIn(sectionRatio));
                  }

                  if (index === 0 && currentTransformValue < 0) {
                    // 첫번째 텍스트 또한 화면에서 사라지기 전에 유지
                    ref!.style.transform = `matrix(1, 0, 0, 1, 0, ${currentTransformValue})`;
                    ref!.style.opacity =
                      sectionRatio > 0.6 && getValueFromRate(1, 0.2, easeIn(sectionRatio));
                  }
                } else {
                  ref!.style.transform = `matrix(1, 0, 0, 1, 0, ${currentTransformValue})`;
                  ref!.style.opacity =
                    sectionRatio < 0.6
                      ? getValueFromRate(0.2, 1, ease(sectionRatio))
                      : getValueFromRate(1, 0, easeIn(sectionRatio));
                }
              } else {
                // 현재 진행중인 애니메이션이 아니라면 video를 중지시키고 duration을 0으로 셋팅해서 다시 재생될 시 처음부터 재생되도록 함
                ref!.style.opacity = '0';
                if (index === DATA.length - 1 && ratio > 1) {
                  // 마지막 텍스트는 화면에서 사라지기 전에 유지
                  ref!.style.transform = `matrix(1, 0, 0, 1, 0, 0)`;
                  ref!.style.opacity = '1';
                } else if (index === 0 && ratio < 0) {
                  ref!.style.transform = `matrix(1, 0, 0, 1, 0, 0)`;
                  ref!.style.opacity = '1';
                }
              }
            });
          }
        }
      }
    };
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Layout
      css={css`
        padding: 0 !important;
      `}
      ref={containerRef}
    >
      <div
        css={css`
          height: 300vh;
        `}
      >
        <TextSection ref={textBoxRef}>
          <Triangle />
          <Stroke />
          <Circle />
          {DATA.map((data, index) => (
            <TextWrapper key={index} ref={ref => (textRef.current[index] = ref)}>
              <Font type="Heading1">{data.text}</Font>
            </TextWrapper>
          ))}
        </TextSection>
      </div>
    </Layout>
  );
};

const Triangle = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: url('/images/intro/section2_triangle.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  height: 40px;
  ${media.sm} {
    height: 56px;
  }
  ${media.md} {
    height: 70px;
  }
`;

const Circle = styled.div`
  z-index: -1;
  position: absolute;
  left: 50%;
  top: calc(100vh / 2 - 128px);
  transform: translateX(-50%);
  width: 256px;
  height: 256px;
  background: url('/images/intro/section2_circle.png');
  background-size: contain;
  background-repeat: no-repeat;

  ${media.sm} {
    width: 363px;
    height: 363px;
    top: calc(100vh / 2 - 181px);
  }
`;

const Stroke = styled.div`
  left: 50%;
  top: 0;
  width: 2px;
  height: 100vh;
  background: #1b46db;
  z-index: -2;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextSection = styled.div`
  flex: 1;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  padding: 0 24px;

  text-align: center;
  /* top: calc(${window.innerHeight / 2}px - 49px);

  ${media.sm} {
    top: calc(${window.innerHeight / 2}px - 121px);
  }
  ${media.md} {
    top: calc(${window.innerHeight / 2}px - 81px);
  } */
  opacity: 0;
`;

export default Section2;
